<template>
  <div></div>
</template>

<!-- 全域共用屬性-->
<script>
  const locationPath = '';
  const breadcrumbListData = [];
  export default {
    name: "Global",
    locationPath,
    breadcrumbListData,
  }
</script>

<style scoped>


</style>
