import Vue from 'vue'
import Router from 'vue-router'

// 模板首頁
const Login = () => import('@/views/login/Login.vue');
const Home = () => import('@/components/layout/Home.vue');
const CompanyHome = () => import('@/views/company/CompanyHome.vue');
const BranchHome = () => import('@/views/company/BranchHome.vue');
const CustomerHome = () => import('@/views/customer/CustomerHome.vue');
const CustomerAssetsHome = () => import('@/views/customer/CustomerAssetsHome.vue');
const StaffHome = () => import('@/views/staff/StaffHome.vue');
const OrderHome = () => import('@/views/order/OrderHome.vue');
const CommissionHome = () => import('@/views/order/CommissionHome.vue');
const WorkHome = () => import('@/views/order/WorkHome.vue');
const ForgotPassword = () => import('@/views/login/ForgotPassword.vue');
Vue.use(Router);

export default new Router({
    //vue的路由在默认的hash模式下,url会带有一个#去掉的话加上mode: 'history', 板成history模式就能够了
    // mode: 'history',
    routes: configRoutes()
})

function configRoutes() {
    return [
        {
            path: '/',
            redirect: 'login',
            name: '登入',
            meta: {
                title: '登入'
            },
            component: Login,
            children: [
                {
                    path: '/login',
                    name: '登入',
                    meta: {
                        title: '登入'
                    },
                    component: Login,
                },
            ]
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            meta: {
                title: '忘記密碼'
            },
            component: ForgotPassword
        },
        {
            path: '/home',
            name: 'home',
            redirect: '/home/order/orderHome',
            meta: {
                title: '車洗洗後台管理系統'
            },
            component: Home,
            children: [
                {
                    path: 'order',
                    name: '工單資訊',
                    redirect: '/home/order/orderHome',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'orderHome',
                            name: '工單列表',
                            component: OrderHome,
                            meta: {title: '工單列表'},
                        },
                        {
                            path: 'workHome',
                            name: '服務項目列表',
                            component: WorkHome,
                            meta: {title: '服務項目列表'},
                        },
                        {
                            path: 'commissionHome',
                            name: '員工傭金列表',
                            component: CommissionHome,
                            meta: {title: '員工傭金列表'},
                        },
                    ]
                },
                {
                    path: 'company',
                    name: '企業資訊',
                    redirect: '/home/company/companyHome',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'companyHome',
                            name: '公司列表',
                            component: CompanyHome,
                            meta: {title: '公司列表'},
                        },
                        {
                            path: 'branchHome',
                            name: '分店列表',
                            component: BranchHome,
                            props: route => ({companyId: Number(route.params.companyId)}),
                            meta: {title: '分店列表'},
                        },
                    ]
                },
                {
                    path: 'customer',
                    name: '會員資訊',
                    redirect: '/home/customer/customerHome',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'customerHome',
                            name: '會員帳號列表',
                            component: CustomerHome,
                            meta: {title: '會員帳號列表'},
                        },
                        {
                            path: 'customerAssetsHome',
                            name: '會員資產列表',
                            component: CustomerAssetsHome,
                            props: route => ({customerId: Number(route.params.customerId)}),
                            meta: {title: '會員資產列表'},
                        },
                    ]
                },
                {
                    path: 'staff',
                    name: '員工資訊',
                    redirect: '/home/staff/staffHome',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'staffHome',
                            name: '員工帳號列表',
                            component: StaffHome,
                            meta: {title: '員工帳號列表'},
                        },
                    ]
                },
            ]
        },
    ]
}
